import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import MtaDriverForm from 'forms/MtaForms/MtaDriverForm';
import { useAddDriverMtaDetails } from 'state/formData/mta/addDriver';
import { CsHero } from 'types/contentStack';

type MtaAddDriverPageContent = {
  csMtaAddDriver: {
    hero: CsHero;
    next_button_text: string;
  };
  csMtaStartDate: {
    next_button_text: string;
  };
};

export const query = graphql`
  query {
    csMtaAddDriver {
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csMtaStartDate {
      next_button_text
    }
  }
`;

type MtaAddDriverPageProps = {
  hasExistingMtaRequest: boolean;
  moveNext: () => void;
};

const MtaAddDriverPage: React.FC<MtaAddDriverPageProps> = ({
  hasExistingMtaRequest,
  moveNext,
}) => {
  const {
    csMtaAddDriver: {
      hero: { heading, subheading },
      next_button_text,
    },
    csMtaStartDate: { next_button_text: startDateNextButtonText },
  } = useStaticQuery<MtaAddDriverPageContent>(query);

  const [driverDetails, updateDriverDetails] = useAddDriverMtaDetails();

  return (
    <>
      <HeroPlain heading={heading} subhead={subheading} />
      <MtaDriverForm
        formState={driverDetails}
        updateFormState={updateDriverDetails}
        moveNextText={hasExistingMtaRequest ? startDateNextButtonText : next_button_text}
        moveNext={moveNext}
      />
    </>
  );
};

export default MtaAddDriverPage;
